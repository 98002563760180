import { useState , useEffect} from 'react';
import { Auth } from "aws-amplify";
import flow from 'lodash/flow';
import map from "lodash/fp/map";
import get from "lodash/fp/get";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import compact from "lodash/compact";
import filter from 'lodash/filter';
import _find from 'lodash/find';
import intersectionBy from "lodash/intersectionBy";
import crypto from 'crypto';

import * as actionsCRM from '../../redux/actions/crm';
import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { useCreateGroupContact, useDeleteGroupContact } from './use-group-contact';
import { useGetMemberActivity, useUpdateMember, useUpdateMemberTag } from './use-mailchimp';
// import { createFilter } from './use-filter';
import { useSnackbar } from 'notistack';

export const useUpdateFilterContacts = () => {
	const updateFilterContacts = useActions(actionsCRM.updateFilterContacts);
	return values => {
		// console.log(values)
		return updateFilterContacts(values);
	};
};

export const useResetAllFilterContacts = () => {
	const resetAllFilterContacts = useActions(actionsCRM.resetAllFilterContacts);
	return values => {
		return resetAllFilterContacts(values);
	};
};

export const useResetOneFilterContacts = () => {
	const resetOneFilterContacts = useActions(actionsCRM.resetOneFilterContacts);
	return value => {
		return resetOneFilterContacts(value);
	};
};
export const useFetchTotalContacts = (onDelete) => {
	const [total, setTotal] = useState(0);
	const { getContactsTotal } = useActions(actions);
	const fetchTotal = async () => {
		try {
      let token = null;
      let result = 0;
      while(true){
        const data = await getContactsTotal({ limit: 3000, nextToken: token });
        token = data.value.listContacts.nextToken;
        result += data.value.listContacts.items.length;
        if(token === null) break;
      }
      setTotal(result);
		} catch(e) {
			console.log(e);
			setTotal(0);
		}
	};
	useEffect(() => { fetchTotal() }, [onDelete]);
	return [{ total }];
};

const useSortBy = () => {
	const { 
		getContactsByFirstName,
		getContactsByLastName,
		getContactsByCreatedAt,
		getContactsByUpdatedAt
	} = useActions(actions);

	return async (action, token, limit) => {
		// console.log(action, token, limit)
		const { type, direction } = action.sortBy;
		try {
			if (type === 'first_name'){
				const data = await getContactsByFirstName({
					limit,
					nextToken: token,
					sortDirection: direction,
					type: 'Contact'
				});
				return {
					result: data.value.listContactsByFirstName.items,
					nextToken: data.value.listContactsByFirstName.nextToken
				}
			}
			else if (type === 'last_name'){
				const data = await getContactsByLastName({
					limit,
					nextToken: token,
					sortDirection: direction,
					type: 'Contact'
				});
				return {
					result: data.value.listContactsByLastName.items,
					nextToken: data.value.listContactsByLastName.nextToken
				}
			}
			else if (type === 'createdAt'){
				const data = await getContactsByCreatedAt({
					limit,
					nextToken: token,
					sortDirection: direction,
					type: 'Contact'
				});
				return {
					result: data.value.listContactsByCreatedAt.items,
					nextToken: data.value.listContactsByCreatedAt.nextToken
				}
			}
			else if (type === 'updatedAt'){
				const data = await getContactsByUpdatedAt({
					limit,
					nextToken: token,
					sortDirection: direction,
					type: 'Contact'
				});
				return {
					result: data.value.listContactsByUpdatedAt.items,
					nextToken: data.value.listContactsByUpdatedAt.nextToken
				}
			}
		} catch (e){
			console.log(e);
			return {
				result: [],
				nextToken: null
			}
		}
	}
};

function isObjectEmptyOrDefault(obj) {
  if (obj === null || obj === false) {
    return true;
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (!isObjectEmptyOrDefault(obj[key])) {
        return false;
      }
    }
    return true;
  }
  if (typeof obj === 'string') {
    return obj.trim() === ''; 
  }
  return false; 
}

const useFilterBy = () => {
	const { 
		getContactsWithAssignedTo,
		getContactsWithGroupContactsDetailed,
		getCompanyWithContacts,
		searchContactsCustom,
		getEmployeeWithContacts,
	} = useActions(actions);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	return async (action) => {
		const {
			company,
			title,
      vip_type,
			city,
			zip_code,
			createdAt_start,
			createdAt_end,
			search,
			state,
			noGroup,
			noRegionGroup,
			noIndustryGroup,
			unassigned,
			groupsMailings,
			groupsIndustry,
			groupsRegion,
			assignedTo
		} = action.filterBy;
		
		try {
			let list = [];
			// Main fetch
			const mainFilter = () => {
				const filter = {};
				if (title) filter.title = { match: title };
        if (vip_type.length > 0) filter.vip_type = { match: vip_type };
				if (city) filter.city = { match: city };
				if (zip_code) filter.zip_code = { eq: zip_code };
				if (createdAt_start && createdAt_end) filter.createdAt = { gte: createdAt_start, lte: createdAt_end };
				if (search) {
          filter.or = [
            { full_name : { matchPhrasePrefix: search.toLowerCase() }},
            { primary_email: { matchPhrasePrefix: search.toLowerCase() }}
          ]
				}
				if (state.length) filter.state = { match: state.map(item => item.name).join() };
				return filter;
			}
			let total = null;
			let token = null;
			let result = [];
			if(!isObjectEmptyOrDefault(mainFilter())){
				while (true) {
					const data = await searchContactsCustom({
						limit: 1000, // max number of search results returned
						filter: mainFilter(),
						sort: {field: 'full_name', direction: 'asc'},
						nextToken: token
					});
					total = data.value.searchContacts.total;
					token = data.value.searchContacts.nextToken;
					result = result.concat(data.value.searchContacts.items);
					if (result.length === total || token === null || total === null) break;
				}
				list = result;

				if(company){
					const companyContacts = result.filter(item => item.company.id === company.id);
					list = intersectionBy(list, companyContacts, 'id');
				}
				if(unassigned){
					const contactsUnassigned = result.filter(item => item.assignedTo == null);
					list = intersectionBy(list, contactsUnassigned, 'id');
				}
				if(assignedTo.length > 0){
					let assignedToList = [];
					assignedTo.map(employee => {
						const contacts = result.filter(item => get('assignedTo.id', item) === employee.id);
						assignedToList.push(...contacts);
					});
					assignedToList = intersectionBy(assignedToList, 'id'); // get unique contacts
					list = intersectionBy(list, assignedToList, 'id'); // filter out main list
				}
				if (noGroup || noRegionGroup || noIndustryGroup){	
					if (noGroup){
						const contactsWithoutGroups = result.filter(item => item.groups.items.length === 0);
						list = intersectionBy(list, contactsWithoutGroups, 'id');
					}
					if (noRegionGroup){
						const contactsWithoutRegionGroup = result.filter(item => {
							return item.groups.items.every(group => group.group.type !== 'region');
						});
						list = intersectionBy(list, contactsWithoutRegionGroup, 'id');
					}
					if (noIndustryGroup){
						const contactsWithoutIndustryGroup = result.filter(item => {
							return item.groups.items.every(group => group.group.type !== 'industry');
						});
						list = intersectionBy(list, contactsWithoutIndustryGroup, 'id');
					}
				}
				if (groupsMailings.length > 0){
					let mailingsList = [];
					groupsMailings.map(group => {
						const contacts = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
						list = intersectionBy(list, contacts, 'id');
						mailingsList.push(...contacts);
					});
					mailingsList = intersectionBy(mailingsList, 'id'); // get unique contacts
					list = intersectionBy(list, mailingsList, 'id'); // filter out main list
				}
				if (groupsRegion.length > 0){
					let regionList = [];
					groupsRegion.map(group => {
						const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
						regionList.push(...contacts);
					});
					regionList = intersectionBy(regionList, 'id'); // get unique contacts
					list = intersectionBy(list, regionList, 'id'); // filter out main list
				}
				if (groupsIndustry.length > 0){
					let industryList = [];
					groupsIndustry.map(group => {
						const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
						industryList.push(...contacts);
					});
					industryList = intersectionBy(industryList, 'id'); // get unique contacts
					list = intersectionBy(list, industryList, 'id'); // filter out main list
				}

			} else {
				if (company){ // Filtering by company
					let result = [];
					// Don't need to add the token because is fetching only one company by companyId;
					const data = await getCompanyWithContacts({ id: company.id, limitContacts: 20000 });
					result = data.value.getCompany.contacts.items;
					if(list.length > 0) list = intersectionBy(list, result, 'id');
					else list = result;
	
					if(unassigned){
						const contactsUnassigned = result.filter(item => item.assignedTo == null);
						list = intersectionBy(list, contactsUnassigned, 'id');
					}
	
					if(assignedTo.length > 0){
						let assignedToList = [];
						assignedTo.map(employee => {
							const contacts = result.filter(item => item.assignedTo.id === employee.id);
							assignedToList.push(...contacts);
						});
						assignedToList = intersectionBy(assignedToList, 'id'); // get unique contacts
						list = intersectionBy(list, assignedToList, 'id'); // filter out main list
					}
	
					if (noGroup || noRegionGroup || noIndustryGroup){	
						if (noGroup){
							const contactsWithoutGroups = result.filter(item => item.groups.items.length === 0);
							list = intersectionBy(list, contactsWithoutGroups, 'id');
						}
						if (noRegionGroup){
							const contactsWithoutRegionGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'region');
							});
							list = intersectionBy(list, contactsWithoutRegionGroup, 'id');
						}
						if (noIndustryGroup){
							const contactsWithoutIndustryGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'industry');
							});
							list = intersectionBy(list, contactsWithoutIndustryGroup, 'id');
						}
					}
	
					if (groupsMailings.length > 0){
						let mailingsList = [];
						groupsMailings.map(group => {
							const contacts = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
							list = intersectionBy(list, contacts, 'id');
							mailingsList.push(...contacts);
						});
						mailingsList = intersectionBy(mailingsList, 'id'); // get unique contacts
						list = intersectionBy(list, mailingsList, 'id'); // filter out main list
					}
	
					if (groupsRegion.length > 0){
						let regionList = [];
						groupsRegion.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							regionList.push(...contacts);
						});
						regionList = intersectionBy(regionList, 'id'); // get unique contacts
						list = intersectionBy(list, regionList, 'id'); // filter out main list
					}
					if (groupsIndustry.length > 0){
						let industryList = [];
						groupsIndustry.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							industryList.push(...contacts);
						});
						industryList = intersectionBy(industryList, 'id'); // get unique contacts
						list = intersectionBy(list, industryList, 'id'); // filter out main list
					}
					
				} else if(assignedTo.length > 0){ // Filtering by assignedTo
					
					let result = []
					await Promise.all(assignedTo.map(async employee => {
						let token = null;
						while(true){
							const data = await getEmployeeWithContacts({ 
								id: employee.id,
								limitContacts: 2000,
								nextTokenContacts: token
							});
							result = result.concat(data.value.getEmployee.contacts.items);
							token = data.value.getEmployee.contacts.nextToken;
							if(token === null) break;
						}
					}))
					if(list.length > 0) list = intersectionBy(list, result, 'id');
					else list = result;
	
					if (noGroup || noRegionGroup || noIndustryGroup){	
						if (noGroup){
							const contactsWithoutGroups = result.filter(item => item.groups.items.length === 0);
							list = intersectionBy(...contactsWithoutGroups, 'id');
						}
						if (noRegionGroup){
							const contactsWithoutRegionGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'region');
							});
							list = intersectionBy(...contactsWithoutRegionGroup, 'id');
						}
						if (noIndustryGroup){
							const contactsWithoutIndustryGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'industry');
							});
							list = intersectionBy(...contactsWithoutIndustryGroup, 'id');
						}
					}
					if (groupsMailings.length > 0){
						let mailingsList = [];
						groupsMailings.map(group => {
							const contacts = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
							list = intersectionBy(list, contacts, 'id');
							mailingsList.push(...contacts);
						});
						mailingsList = intersectionBy(mailingsList, 'id'); // get unique contacts
						list = intersectionBy(list, mailingsList, 'id'); // filter out main list
					}
	
					if (groupsRegion.length > 0){
						let regionList = [];
						groupsRegion.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							regionList.push(...contacts);
						});
						regionList = intersectionBy(regionList, 'id'); // get unique contacts
						list = intersectionBy(list, regionList, 'id'); // filter out main list
					}
					
					if (groupsIndustry.length > 0){
						let industryList = [];
						groupsIndustry.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							industryList.push(...contacts);
						});
						industryList = intersectionBy(industryList, 'id'); // get unique contacts
						list = intersectionBy(list, industryList, 'id'); // filter out main list
					}
	
				} else if(unassigned){ // Filtering by unassigned
					let token = null;
					let result = [];
					let i = 0;
					while(true){
						enqueueSnackbar(`Looking through contacts ${i*1000+1}-${(i+1)*1000}`, { variant: 'default', autoHideDuration: 1000 });
						const data = await getContactsWithAssignedTo({ 
							limit: 1000,
							nextToken: token 
						});
						// console.log(data)
						result = result.concat(data.value.listContacts.items);
						token = data.value.listContacts.nextToken;
						i = i+1;
						if(token === null) break;
					}
					if(list.length > 0) list = intersectionBy(list, result, 'id');
					else list = result;
	
					const contactsUnassigned = result.filter(item => item.assignedTo == null);
					list = intersectionBy(list, contactsUnassigned, 'id');
	
					if (noGroup || noRegionGroup || noIndustryGroup){	
						if (noGroup){
							const contactsWithoutGroups = result.filter(item => item.groups.items.length === 0);
							list = intersectionBy(...contactsWithoutGroups, 'id');
						}
						if (noRegionGroup){
							const contactsWithoutRegionGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'region');
							});
							list = intersectionBy(...contactsWithoutRegionGroup, 'id');
						}
						if (noIndustryGroup){
							const contactsWithoutIndustryGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'industry');
							});
							list = intersectionBy(...contactsWithoutIndustryGroup, 'id');
						}
					}
					if (groupsMailings.length > 0){
						let mailingsList = [];
						groupsMailings.map(group => {
							const contacts = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
							list = intersectionBy(list, contacts, 'id');
							mailingsList.push(...contacts);
						});
						mailingsList = intersectionBy(mailingsList, 'id'); // get unique contacts
						list = intersectionBy(list, mailingsList, 'id'); // filter out main list
					}
	
					if (groupsRegion.length > 0){
						let regionList = [];
						groupsRegion.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							regionList.push(...contacts);
						});
						regionList = intersectionBy(regionList, 'id'); // get unique contacts
						list = intersectionBy(list, regionList, 'id'); // filter out main list
					}
					
					if (groupsIndustry.length > 0){
						let industryList = [];
						groupsIndustry.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							industryList.push(...contacts);
						});
						industryList = intersectionBy(industryList, 'id'); // get unique contacts
						list = intersectionBy(list, industryList, 'id'); // filter out main list
					}
					
				} else if (noGroup || noRegionGroup || noIndustryGroup || groupsMailings.length > 0 || groupsRegion.length > 0 || groupsIndustry.length > 0){ // Filtering by groups
					let token = null;
					let result = [];
					let i = 0;
					while(true){
						enqueueSnackbar(`Looking through contacts ${i*1000+1}-${(i+1)*1000}`, { variant: 'default', autoHideDuration: 1000 });
						const data = await getContactsWithGroupContactsDetailed({ 
							limit: 1000, 
							nextToken: token 
						});
						result = result.concat(data.value.listContacts.items);
						token = data.value.listContacts.nextToken;
						i = i+1;
						if (token === null) break;
					}
					if(list.length > 0) list = intersectionBy(list, result, 'id');
					else list = result;
	
					if (noGroup || noRegionGroup || noIndustryGroup){	
						if (noGroup){
							const contactsWithoutGroups = result.filter(item => item.groups.items.length === 0);
							list = intersectionBy(list, contactsWithoutGroups, 'id');
						}
						if (noRegionGroup){
							const contactsWithoutRegionGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'region');
							});
							list = intersectionBy(list, contactsWithoutRegionGroup, 'id');
						}
						if (noIndustryGroup){
							const contactsWithoutIndustryGroup = result.filter(item => {
								return item.groups.items.every(group => group.group.type !== 'industry');
							});
							list = intersectionBy(list, contactsWithoutIndustryGroup, 'id');
						}
					}
	
					if (groupsMailings.length > 0){
						let mailingsList = [];
						groupsMailings.map(group => {
							const contacts = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
							list = intersectionBy(list, contacts, 'id');
							mailingsList.push(...contacts);
						});
						mailingsList = intersectionBy(mailingsList, 'id'); // get unique contacts
						list = intersectionBy(list, mailingsList, 'id'); // filter out main list
					}
	
					if (groupsRegion.length > 0){
						let regionList = [];
						groupsRegion.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							regionList.push(...contacts);
						});
						regionList = intersectionBy(regionList, 'id'); // get unique contacts
						list = intersectionBy(list, regionList, 'id'); // filter out main list
					}
	
					if (groupsIndustry.length > 0){
						let industryList = [];
						groupsIndustry.map(group => {
							const contacts = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
							industryList.push(...contacts);
						});
						industryList = intersectionBy(industryList, 'id'); // get unique contacts
						list = intersectionBy(list, industryList, 'id'); // filter out main list
					}
					
				}
			}

			const direction = action.sortBy.direction.toLowerCase();

			return {
				result: orderBy(list, [action.sortBy.type], [direction]),
				total: list.length
			}
		} catch (e){
			console.log(e);
			enqueueSnackbar('Error fetching contacts...', { variant: 'error', persist: true,
			errors: e.errors });
			return {
				result: [],
				total: 0
			}
		}
	}
};

export const useContacts = (action, token, limit) => {
	const _sortBy = useSortBy();
	const _filterBy = useFilterBy();

	const [contacts, setContacts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [nextToken, setNextToken] = useState(null);
	const [prevFilterBy, setPrevFilterBy] = useState(null);
	const [prevSortBy, setPrevSortBy] = useState(null);
	const [total, setTotal] = useState(null);

	const fetchContacts = async () => {
		setIsLoading(true);
		if (action.filterBy == null) {
			const { result, nextToken } = await _sortBy(action, token, limit);
			if (action.nextPage) {
				setContacts(prev => prev.concat(result));
			} else {
				setContacts(result);
			}
			setTotal(null);
			setNextToken(nextToken);
		} 
		else {
			if(prevFilterBy !== action.filterBy){
				const { result, total } = await _filterBy(action);
				setContacts(result);
				setTotal(total);
				setPrevFilterBy(action.filterBy);
				setPrevSortBy(action.sortBy);
			} else {	
				if (prevSortBy !== action.sortBy){
					setContacts(orderBy(contacts, [action.sortBy.type], [action.sortBy.direction.toLowerCase()]));
				}
			}
		}
		setIsLoading(false);
	}
	
	useEffect(() => { fetchContacts() }, [action, limit]);

	return [{ contacts, nextToken, isLoading, total }];
};


export const useGetContact = () => {
	const { getContact } = useActions(actions);
	return async (id) => {
		try {
			const { value } = await getContact({ id });
			return value;
		} catch(e){
			console.log(e)
		}
	}
};

export const useGetUnassignedContacts = trigger => {
	const { getContactsWithAssignedToJustIds } = useActions(actions);
	const [unassigned, setUnassigned] = useState([]);
	const fetchData = async () => {
		try {
			let token = null;
			let result = [];
			while(true){
				const data = await getContactsWithAssignedToJustIds({ 
					limit: 3000, 
					nextToken: token 
				});
				result = result.concat(data.value.listContacts.items);
				token = data.value.listContacts.nextToken;
				if(token === null) break;
			}
			setUnassigned(result.filter(item => item.assignedTo == null));
		} catch(e) {
			console.log(e);
		}
	};
	useEffect(() => { fetchData() }, [trigger]);
	return [{ unassigned }];
};

export const useFindContacts = () => {
	const { searchContactsCustom } = useActions(actions);
	return async ({ filter }) => {
		try {
			const { value } = await searchContactsCustom({
				limit: 1000,
				filter
			});
			// console.log(value)
			return value.searchContacts.items;
		} catch(e){
			console.log(e);
		}
	}
};

export const useContact = (id, triggerFetch) => {
	const [contact, setContact] = useState({});
	const [notes, setNotes] = useState([]);
	const [contactForms, setContactForms] = useState([]);
	const [downloadForms, setDownloadForms] = useState([]);
  const [submissionForms, setSubmissionForms] = useState([]);
	const [tradeForms, setTradeForms] = useState([]);
	const [mailchimpActivity, setMailchimpActivity] = useState([]);
	const [messages, setMessages] = useState([]);
	const [events, setEvents] = useState([]);
	const [tasksTodo, setTasksTodo] = useState([]);
	const [tasksCompleted, setTasksCompleted] = useState([]);
  const [pipelines, setPipelines] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { getContact, getGroupContact } = useActions(actions);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const _contact = await getContact({ id });
			// console.log('contact', _contact)
			setMessages(orderBy(_contact.value.messages.items, ['updatedAt'], ['desc']));
			setNotes(orderBy(_contact.value.notes.items, ['updatedAt'], ['desc']));
			setContactForms(orderBy(_contact.value.contactForms.items, ['updatedAt'], ['desc']));
			setDownloadForms(orderBy(_contact.value.downloadForms.items, ['updatedAt'], ['desc']));
      setSubmissionForms(orderBy(_contact.value.submissionForms.items, ['updatedAt'], ['desc']));
			setTradeForms(orderBy(_contact.value.tradeForms.items, ['updatedAt'], ['desc']));
			setEvents(orderBy(_contact.value.events.items, ['updatedAt'], ['desc']));
      		setTasksTodo(orderBy(filter(_contact.value.tasks.items, ['status', 'TODO']),['due_date'], ['asc'] ));
      		setTasksCompleted(orderBy(filter(_contact.value.tasks.items, ['status', 'COMPLETED']),['due_date'], ['asc'] ));
      setPipelines(orderBy(_contact.value.pipeline.items, ['updatedAt'], ['desc']));
			const _groups = await Promise.all(_contact.value.groups.items.map(async item => {
				const _group = await getGroupContact({ id: item.id });
				return { ..._group.value.group, groupContactId: item.id, groupContactMailchimpStatus: item.mailchimp_status };
			}));
			setContact({ ..._contact.value, groups: _groups });
			// setContact(prev => Object.assign(prev, { groups: _groups }));
			let activities = [];
			const getMailchimpActivity = await Promise.all(_groups.map(async group => {
				if (group.mailchimp_list_id){
					try {
						// Possible activities: abuse, bounce, click, open, sent, unsub, or ecomm.
						const memberActivity = await useGetMemberActivity({
							list_id: group.mailchimp_list_id,
							mailchimp_id: _contact.value.mailchimp_id
						});
						// console.log('memberActivity',memberActivity);
						activities = compact(activities.concat(memberActivity.data.activity));
					} catch(e) {
						console.log(e)
					}
				}
			}));
			// Group activities by campaign_id
			const activitiesByCampaign = groupBy(activities, el => el.campaign_id);
			// Get most recent activity by campaign_id
			const mostRecentActivities = Object.keys(activitiesByCampaign).map(campaign_id => {
				const list = orderBy(activitiesByCampaign[campaign_id], ['timestamp'], ['desc']);
				return list[0];
			});
			setMailchimpActivity(mostRecentActivities);
		} catch(e) {
			console.log(e);
		}
		setIsLoading(false);
	};

	useEffect(() => { fetchData() }, [id, triggerFetch]);
	return [{ contact, notes, contactForms, downloadForms, submissionForms, tradeForms, mailchimpActivity, isLoading, messages, events, tasksTodo, tasksCompleted, pipelines }];
};

export const useCreateContact = () => {
	const { createContact, createActivity } = useActions(actions);
	const createGroupContact = useCreateGroupContact();
  
	return async values => {
		console.log(values)
		// CREATE CONTACT
		const newContact = await createContact({ input: {
			first_name: values.first_name,
			last_name: values.last_name,
			full_name: values.last_name ? values.first_name + " " + values.last_name : values.first_name,
			primary_email: values.primary_email.toLowerCase(),
			primary_phone: values.primary_phone,
			street_address: values.street_address,
			city: values.city,
			state: get('state.name', values) || null,
			country: values.country,
			zip_code: values.zip_code,
			// vip: values.vip,
      vip_type: get('vip_type.name', values) || null,
			title: values.title,
			sendNotification: values.sendNotification,
			contactAssignedToId: get('assignedTo.id', values) || null,
			contactCompanyId: get('company.id', values),
			mailchimp_id: crypto.createHash('md5').update(values.primary_email.toLowerCase()).digest('hex'),
			type: 'Contact',
      createdBy: Auth.user.username
		}});

    const { id: contactId } = newContact.value;
    
    // Log Activity
    if(contactId){
      const user = await Auth.currentAuthenticatedUser();
      await createActivity({
        input: {
          activityId: contactId,
          type: 'ACTIVITY',
          creatorId: user.username,
          activityCreatorId: user.username,
          action: "CREATED",
          activityType: "CONTACT",
          payload: {
            name: newContact.value.full_name
          }
        }
      });
    }
		// DO CONNECTION CONTACT AND GROUPS
		if (values.groups) {
			const groupsContact = values.groups.map((group, index) => {
        setTimeout(async () => {
          await createGroupContact({
            groupContactContactId: contactId,
            groupContactGroupId: group.id,
            mailchimp_status: 'subscribed'
          });
        }, 1000*index);
      });
		}

		return newContact; 
	}
};

export const useDeleteContact = () => {
	const deleteGroupContact = useDeleteGroupContact();
	const { deleteContact, createActivity, getPipelinesByContact, deletePipeline } = useActions(actions);
	const { getContactWithGroupContacts } = useActions(actions);

	return async ({ selected }) => {
		// console.log(selected)
		const contacts = await Promise.all(selected.map(async contactId => {
			const data = await getContactWithGroupContacts({ id: contactId });
			return data.value.getContact;
		}));
    // Delete Pipelines
    const pipelines = await Promise.all(selected.map(async contactId => {
      const data = await getPipelinesByContact({ contactId: { eq: contactId }, type: "Pipeline" });
      return data.value.listPipelinesByContact.items
    }));
    if (pipelines) {
      const deletePipelineItems = await Promise.all(pipelines.flat().map(async pipeline => {
        return await flow(
          map((item) => deletePipeline({ 
            input: {
              id: item.id
            }
          })),
          promises => Promise.all(promises),
        )(pipelines.flat());
      })); 
    }
		// Delete GroupContacts
		const deleteGroupContacts = await Promise.all(contacts.map(async contact => {
			return await flow(
				map(({ id }) => deleteGroupContact({ id })),
				promises => Promise.all(promises),
			)(contact.groups.items);
		}));
		// console.log('deleteGroupContacts', deleteGroupContacts)
		
		// Delete Contacts
		const deleteContacts = flow(
      map(contact => {
        // console.log(contact);
        deleteContact({ input: { id: contact.id }});
        logDeleteContact({ 
					contactId: contact.id,
          contactName: contact.last_name ? contact.first_name + ' ' + contact.last_name : contact.first_name
        });
      } 
      ),
			promises => Promise.all(promises),
    );
    
    const logDeleteContact = async({contactId, contactName}) => {
      const user = await Auth.currentAuthenticatedUser();
      await createActivity({
        input: {
          activityId: contactId,
          type: 'ACTIVITY',
          creatorId: user.username,
          activityCreatorId: user.username,
          action: "DELETED",
          activityType: "CONTACT",
          payload: {
            name: contactName
          }
        }
      });
    }
		return await deleteContacts(contacts);
	}
};

export const useUpdateContact = () => {
	const { updateContact, getContact } = useActions(actions);
	const createGroupContact = useCreateGroupContact();
	const deleteGroupContact = useDeleteGroupContact();

	return async values => {
		// console.log(values)

		// GET PREVIOUS CONTACT TO UPDATE MAILCHIMP MEMBER WITH PREVIOUS MAILCHIMP_ID
		const previousContact = await getContact({ id: values.id });
		// console.log(previousContact)

		// UPDATE CONTACT
		const updatedContact = await updateContact({ input: {
			id: values.id,
			first_name: values.first_name,
			last_name: values.last_name,
			full_name: values.last_name ? values.first_name + " " + values.last_name : values.first_name,
			primary_email: values.primary_email.toLowerCase(),
			primary_phone: values.primary_phone,
			street_address: values.street_address,
			city: values.city,
			state: get('state.name', values) || null,
			country: values.country,
			zip_code: values.zip_code,
			// vip: values.vip,
      vip_type: get('vip_type.name', values) || null,
			title: values.title,
			sendNotification: values.sendNotification,
			contactAssignedToId: get('assignedTo.id', values),
			contactCompanyId: values.company && values.company.id,
			mailchimp_id: crypto.createHash('md5').update(values.primary_email.toLowerCase()).digest('hex'),
			type: 'Contact'
		}});
		// console.log('UPDATED CONTACT', updatedContact)

		// UPDATE MAILCHIMP MEMBER (IF EMAIL CHANGES)
		const previousEmail = get('primary_email', previousContact.value).toLowerCase();
		if (previousEmail &&  previousEmail !== values.primary_email.toLowerCase()) {
			values.groups && values.groups.map(async group => {
				if (group.mailchimp_list_id){
					const groupContact = previousContact.value.groups.items.find(item => item.group.id === group.id);
					// console.log('groupContact', groupContact)
					const mailchimp_status = get('mailchimp_status', groupContact) || 'subscribed';
					// console.log('mailchimp_status',mailchimp_status)
					const updateMailchimpMember = await useUpdateMember({ 
						values: {
							email_address: values.primary_email.toLowerCase(),
							status_if_new: mailchimp_status,
							status: mailchimp_status,
							merge_fields: {
								FNAME: values.first_name,
								LNAME: values.last_name,
								EMAIL: values.primary_email.toLowerCase(),
							}
						},
						list_id: group.mailchimp_list_id,
						mailchimp_id: previousContact.value.mailchimp_id,
					});
					// console.log('updateMailchimpMember', updateMailchimpMember)
				}
			})
		}

		const { id: contactId } = updatedContact.value;

		const initalGroupsContacts = get('value.groups.items', updatedContact)
			.map(item => ({ ...item, groupContactId: item.id }))
		;
		// console.log('initalGroupsContacts', initalGroupsContacts);

		// ADD AND/OR REMOVE GROUP CONTACTS
		const addGroupsContacts = values.groups && values.groups
			.filter(group => !group.groupContactId)
			.map(async group => await createGroupContact({
				groupContactContactId: contactId,
				groupContactGroupId: group.id,
				mailchimp_status: 'subscribed'
			}));

		const removeGroupsContacts = Promise.all(
			differenceBy(initalGroupsContacts, values.groups, 'groupContactId')
			.map(groupContact => deleteGroupContact({ id: groupContact.id }))
		);
		// console.log('removeGroupsContacts', removeGroupsContacts)
		
		return updatedContact; 
	}
};

export const useUpdateMailchimpStatus = () => {
	const { updateGroupContact } = useActions(actions);
  
	return async values => {
	  const { contact, newStatus, group } = values;
	  try {
		const updatedMember = await useUpdateMember({
		  values: {
			email_address: contact.primary_email,
			status_if_new: newStatus,
			status: newStatus,
		  },
		  list_id: group.mailchimp_list_id,
		  mailchimp_id: contact.mailchimp_id,
		});
		// console.log('updatedMember',updatedMember)
		if (updatedMember.success) {
		  const updatedGroupContact = await updateGroupContact({
			input: {
			  id: group.groupContactId,
			  mailchimp_status: newStatus
			}
		  });
		  // console.log('updatedGroupContact',updatedGroupContact)
		}
		return updatedMember;
	  } catch(e){
		console.log(e);
		return(e);
	  }
	}
  };


export const useUpdateMailchimpTagStatus = () => {
	const { updateGroupContact } = useActions(actions);
  
	return async values => {
	  const { contact, group, tag, status } = values;
	  try {
		  const updatedMemberTag = await useUpdateMemberTag({
			  list_id: group.mailchimp_newsletter_id,
			  mailchimp_id: contact.mailchimp_id,
			  tag: tag,
			  status: status
		  })
		return updatedMemberTag;
	  } catch(e){
		console.log(e);
		return(e);
	  }
	}
  };

export const useAssignContact = () => {
	const { updateContact } = useActions(actions);

	return async values => {
		// console.log(values)
		const updatedContacts = await Promise.all(values.selected.map(contactId => {
			return updateContact({ input: {
				id: contactId,
				contactAssignedToId: get('id', values.assignedTo),
			}});
		}));
		// console.log('UPDATED CONTACTS', updatedContacts)
		return updatedContacts; 
	}
};

// export const useContactsByName = () => {

// 	const [contacts, setContacts] = useState([]);
// 	const [isLoading, setIsLoading] = useState(false);
// 	const { getContactsWithNameAndEmail } = useActions(actions);

// 	const fetchContacts = async () => {
// 		setIsLoading(true);
// 		try {
// 			const { value } = await getContactsWithNameAndEmail({ limit: 20000 });
// 			setContacts(orderBy(value.listContacts.items, ['first_name'], ['asc']));
// 		} catch(err) {
// 			console.log(err)
// 		}
// 		setIsLoading(false);
// 	}
	
// 	useEffect(() => { fetchContacts() }, []);

// 	return [{ contacts, isLoading }];
// };

export const useMergeContacts = () => {
	const { 
		updateContact, 
		getContact, 
		updateNote, 
		updateContactUsForm, 
		updateDownloadFileForm,
		updateMessageContact,
		updateEventAttendee,
		updateTask
	} = useActions(actions);
	const createGroupContact = useCreateGroupContact();
	const deleteGroupContact = useDeleteGroupContact();
	const deleteContact = useDeleteContact()

	/*
		STEPS
			- CONTACT TO UPDATE (Contact One, in the left)
				- Remove all groups
				- Update contact with new values
				- Add new groups
				- Update contactId of all Notes from contactToDelete to contactToUpdate
				- Update contactId of all Contact Forms from contactToDelete to contactToUpdate
				- Update contactId of all Download Forms from contactToDelete to contactToUpdate
				- Update contactId of all Messages from contactToDelete to contactToUpdate
				- Update contactId of all EventAttendees from contactToDelete to contactToUpdate
				- Update contactId of all Tasks from contactToDelete to contactToUpdate
			
			- CONTACT TO DELETE (Contact Two, in the right)
				- Delete contact (useDeleteContact)
	*/

	return async ({ contactToUpdate, contactToDelete, newGroups, newValues }) => {
		// console.log('contactToUpdate',contactToUpdate)
		// console.log('contactToDelete',contactToDelete)
		// console.log('newGroups',newGroups)
		// console.log('newValues',newValues)

		try {
			// REMOVE ALL GROUPS FROM CONTACT_TO_UPDATE
			const mailingsGroupContactId = get('mailings.groupContactId', contactToUpdate) || null;
			const regionGroupContactId = get('region.groupContactId', contactToUpdate) || null;
			const industryGroupContactId = get('industry.groupContactId', contactToUpdate) || null;
		
			const deletedGroups = await Promise.all([
				mailingsGroupContactId && await deleteGroupContact({ id: mailingsGroupContactId }),
				regionGroupContactId && await deleteGroupContact({ id: regionGroupContactId }),
				industryGroupContactId && await deleteGroupContact({ id: industryGroupContactId }),
			]);
			console.log({deletedGroups})

			// UPDATE CONTACT WITH NEW VALUES
			const updatedContact = await updateContact({ input: {
				id: contactToUpdate.id,
				primary_email: newValues.email ? newValues.email.toLowerCase() : null,
				primary_phone: newValues.phone,
				street_address: newValues.address.street_address,
				city: newValues.address.city,
				state: newValues.address.state,
				country: newValues.address.country,
				zip_code: newValues.address.zip_code,
				title: newValues.title,
				mailchimp_id: newValues.email ? crypto.createHash('md5').update(newValues.email.toLowerCase()).digest('hex') : null,
				contactCompanyId: newValues.company ? newValues.company.id : null,
				contactAssignedToId: newValues.assignedTo ? newValues.assignedTo.id : null,
			}});
			// console.log('updatedContact', updatedContact)

			// ADD NEW GROUPS
			const mailingsGroupId = get('mailings.id', newValues) || get('mailings.id', newGroups) || null;
			const regionGroupId = get('region.id', newValues) || get('region.id', newGroups) || null;
			const industryGroupId = get('industry.id', newValues) || get('industry.id', newGroups) || null;
			// console.log('mailingsGroupId',mailingsGroupId)
			// console.log('regionGroupId',regionGroupId)
			// console.log('industryGroupId',industryGroupId)

			const createdGroups = await Promise.all([
				mailingsGroupId && await createGroupContact({ 
					groupContactContactId: contactToUpdate.id,
					groupContactGroupId: mailingsGroupId,
					mailchimp_status: 'subscribed' 
				}),
				regionGroupId && await createGroupContact({ 
					groupContactContactId: contactToUpdate.id,
					groupContactGroupId: regionGroupId,
					mailchimp_status: 'subscribed' 
				}),
				industryGroupId && await createGroupContact({ 
					groupContactContactId: contactToUpdate.id,
					groupContactGroupId: industryGroupId,
					mailchimp_status: 'subscribed' 
				})
			]);
			console.log({createdGroups})

			const contact = await getContact({ id: contactToDelete.id }); // contactToDelete
			// console.log('contact',contact)

			// UPDATE ALL NOTES FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const notes = contact.value.notes.items;
			const updatedNotes = await Promise.all(notes.map(note => updateNote({
				input: {
					id: note.id,
					noteContactId: contactToUpdate.id
				}
			})));
			// console.log('updatedNotes',updatedNotes)

			// UPDATE ALL CONTACT FORMS FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const contactForms = contact.value.contactForms.items;
			const updatedContactForms = await Promise.all(contactForms.map(contactForm => updateContactUsForm({
				input: {
					id: contactForm.id,
					contactID: contactToUpdate.id
				}
			})));
			// console.log('updatedContactForms',updatedContactForms)

			// UPDATE ALL DOWNLOAD FORMS FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const downloadForms = contact.value.downloadForms.items;
			const updatedDownloadForms = await Promise.all(downloadForms.map(downloadForm => updateDownloadFileForm({
				input: {
					id: downloadForm.id,
					contactID: contactToUpdate.id
				}
			})));
			// console.log('updatedDownloadForms',updatedDownloadForms)

			// UPDATE ALL MESSAGES FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const messages = contact.value.messages.items;
			const updatedMessages = await Promise.all(messages.map(messageContact => updateMessageContact({
				input: {
					id: messageContact.id,
					messageContactContactId: contactToUpdate.id,
				}
			})));
			// console.log('updatedMessages',updatedMessages)

			// UPDATE ALL EVENT ATTENDEES FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const events = contact.value.events.items;
			console.log(events)
			const updatedEvents = await Promise.all(events.map(eventAttendee => updateEventAttendee({
				input: {
					id: eventAttendee.id,
					eventAttendeeAttendeeContactId: contactToUpdate.id,
				}
			})));
			// console.log('updatedEvents',updatedEvents)

			// UPDATE ALL TASKS FROM CONTACT_TO_DELETE TO CONTACT_TO_UPDATE
			const tasks = contact.value.tasks.items;
			console.log(tasks)
			const updatedTasks = await Promise.all(tasks.map(task => updateTask({
				input: {
					id: task.id,
					taskContactId: contactToUpdate.id,
				}
			})));
			// console.log('updatedTasks',updatedTasks)

			// DELETE CONTACT_TO_DELETE
			const deletedContact = await deleteContact({ selected: [ contactToDelete.id ]}); 
			// console.log('deletedContact',deletedContact)
		} catch(err){
			console.log(err);
		}
	}
};