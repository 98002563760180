import { useState, useEffect } from "react";
import flow from 'lodash/flow';
import map from "lodash/fp/map";
import sortBy from 'lodash/sortBy';
import get from "lodash/fp/get";
import getOr from 'lodash/fp/getOr';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';
import _find from 'lodash/find';
import differenceBy from "lodash/differenceBy";
import compact from "lodash/fp/compact";
import intersectionBy from "lodash/intersectionBy";
import flatMap from 'lodash/flatMap';
import crypto from 'crypto';

import * as actionsCRM from '../../redux/actions/crm';
import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { createFilter } from './use-filter';
import { useGetMemberActivity, useUpdateMember, useGetCampaignRecipients, useUpdateMemberTag } from './use-mailchimp';
import { useCreateGroupCompany, useDeleteGroupCompany } from './use-group-company';
import { useSnackbar } from 'notistack';

import { Auth } from 'aws-amplify';

export const useUpdateFilterCompanys = () => {
	const updateFilterCompanys = useActions(actionsCRM.updateFilterCompanys);
	return values => {
		// console.log(values)
		return updateFilterCompanys(values);
	};
};

export const useResetAllFilterCompanys = () => {
	const resetAllFilterCompanys = useActions(actionsCRM.resetAllFilterCompanys);
	return values => {
    // console.log(values)
		return resetAllFilterCompanys(values);
	};
};

export const useResetOneFilterCompanys = () => {
	const resetOneFilterCompanys = useActions(actionsCRM.resetOneFilterCompanys);
	return value => {
    // console.log(value)
		return resetOneFilterCompanys(value);
	};
};

export const useCreateCompany = () => {
  const { createCompany, createActivity } = useActions(actions);
  const createGroupCompany = useCreateGroupCompany();

	return async values => {
    values.groups = compact([
      get('mailingGroups', values), 
      get('regionGroups', values), 
      get('industryGroups', values)
    ]);
    const email = values.email ? values.email.toLowerCase() : values.email;
    const mailchimp_id = email ? crypto.createHash('md5').update(email).digest('hex') : undefined;
    // console.log('values',values)
		const newCompany = await createCompany({ input: {
      name: values.name,
      email,
      mailchimp_id,
      phone: values.phone,
      website: values.website,
      street_address: values.street_address,
      city: values.city,
      state: get('state.name', values) || null,
      zip_code: values.zip_code,
      // vip: values.vip,
      vip_type: get('vip_type.name', values) || null,
      sendNotification: values.sendNotification,
      country: values.country,
      companyAssignedToId: get('assignedTo.id', values),
      type: 'Company',
      createdBy: Auth.user.username
    }});
    // console.log('NEW COMPANY', newCompany);
    const { id: companyId } = newCompany.value;
    
    // Log Activity
    if(companyId){
      const user = await Auth.currentAuthenticatedUser();
      await createActivity({
        input: {
          activityId: companyId,
          type: 'ACTIVITY',
          creatorId: user.username,
          activityCreatorId: user.username,
          action: "CREATED",
          activityType: "COMPANY",
          payload: {
            name: newCompany.value.name
          }
        }
      });
    }

    // DO CONNECTION COMPANY AND GROUPS
		if (values.groups) {
			const groupsCompany = values.groups.map(({ id }) => {
				return createGroupCompany({
					groupCompanyCompanyId: companyId,
          groupCompanyGroupId: id,
          mailchimp_status: 'subscribed'
				});
      });
      // console.log('GROUPS COMPANY', groupsCompany);
    }

    return newCompany; 
	}
};

// export const useCompanys = (onCreate) => {
// 	const [companys, setCompanys] = useState({});
// 	const { getCompanys } = useActions(actions);
// 	const fetchCompanys = async () => {
// 		try {
// 			const data = await getCompanys({ limit: 1000 });
// 			setCompanys(sortBy(data.value.listCompanys.items, ['name']));
// 		} catch(e) {
// 			console.log(e);
// 		}
// 	};
// 	useEffect(() => { fetchCompanys() }, [onCreate]);
// 	return [{ companys }];
// };

// export const useCompanyList = (onCreate) => {
//   const [companys, setCompanys] = useState({});
// 	const { getCompanysWithName } = useActions(actions);
// 	const fetchCompanys = async () => {
// 		try {
// 			const data = await getCompanysWithName({ limit: 1000 });
// 			setCompanys(sortBy(data.value.listCompanys.items, ['name']));
// 		} catch(e) {
// 			console.log(e);
// 		}
// 	};
// 	useEffect(() => { fetchCompanys() }, [onCreate]);
// 	return [{ companys }];
// }

export const useCompanyList = (onCreate) => {
  const [companys, setCompanys] = useState([]);
  const { getCompanysWithName } = useActions(actions);
  const [token, setToken] = useState(null);

	const fetchCompanys = async () => {
    try {
      const data = await getCompanysWithName({ 
        sortDirection: 'ASC',
        type: 'Company',
        limit: 3000, 
        nextToken: token
      });
      setCompanys(prev => prev.concat(data.value.listCompanysByName.items));
      const nextToken = data.value.listCompanysByName.nextToken;
      if (nextToken != null){
        setToken(nextToken);
      }
    } catch(e) {
      console.log(e);
    }
	};
	useEffect(() => { fetchCompanys() }, [onCreate, token]);
	return [{ companys }];
}

export const useCompanys = (action, token, limit) => {
  const _sortBy = useSortBy();
  const _filterBy = useFilterBy();

  const [companys, setCompanys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	const [nextToken, setNextToken] = useState(null);
  const [prevFilterBy, setPrevFilterBy] = useState(null);
	const [prevSortBy, setPrevSortBy] = useState(null);
  const [total, setTotal] = useState(null);
  
  const fetchCompanys = async () => {
    setIsLoading(true);
		if (action.filterBy == null) {
			const { result, nextToken } = await _sortBy(action, token, limit);
			if (action.nextPage) {
				setCompanys(prev => prev.concat(result));
			} else {
				setCompanys(result);
			}
			setTotal(null);
			setNextToken(nextToken);
		} 
		else {
      if(prevFilterBy !== action.filterBy){
				const { result, total } = await _filterBy(action);
				setCompanys(result);
			  setTotal(total);
				setPrevFilterBy(action.filterBy);
				setPrevSortBy(action.sortBy);
			} else {	
				if (prevSortBy !== action.sortBy){
					setCompanys(orderBy(companys, [action.sortBy.type], [action.sortBy.direction.toLowerCase()]));
				}
			}
		}
		setIsLoading(false);
	};

  useEffect(() => { fetchCompanys() }, [action, limit]);

  return [{ companys, nextToken, isLoading, total }];
};

const useSortBy = () => {
  const {
    getCompanysByName,
    getCompanysByCreatedAt,
    getCompanysByUpdatedAt      
  } = useActions(actions);

  return async (action, token, limit) => {
    const { type, direction } = action.sortBy;
    const input = {
      limitCompanys: limit,
      limitContacts: 20000,
      nextToken: token,
      sortDirection: direction,
      type: 'Company'
    };

    try {
      if(type === 'name'){
        const data = await getCompanysByName(input);
        // console.log('BY NAME', data);
        return {
          result: data.value.listCompanysByName.items,
          nextToken: data.value.listCompanysByName.nextToken
        }
      }
      else if (type === 'createdAt'){
        const data = await getCompanysByCreatedAt(input);
        // console.log('BY CREATED AT', data);
				return {
					result: data.value.listCompanysByCreatedAt.items,
          nextToken: data.value.listCompanysByCreatedAt.nextToken
				}
			}
			else if (type === 'updatedAt'){
				const data = await getCompanysByUpdatedAt(input);
        // console.log('BY UPDATED AT', data);
				return {
					result: data.value.listCompanysByUpdatedAt.items,
          nextToken: data.value.listCompanysByUpdatedAt.nextToken
				}
			}
    } catch (e) {
      console.log(e);
      return {
        result: [],
        nextToken: null
      }
    }
  }
};

function isObjectEmptyOrDefault(obj) {
  if (obj === null || obj === false) {
    return true;
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (!isObjectEmptyOrDefault(obj[key])) {
        return false;
      }
    }
    return true;
  }
  if (typeof obj === 'string') {
    return obj.trim() === ''; 
  }
  return false; 
}

const useFilterBy = () => {
	const { 
    getCompanys, 
    getEmployeeWithCompanys,
    getCompanysWithGroupCompanys,
    getGroupWithCompanys,
    searchCompanysCustom
  } = useActions(actions);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	return async (action) => {
		const {
			city,
      zip_code,
      vip_type,
      search,
			createdAt_start,
      createdAt_end,
      unassigned,
      size,
      state,
      assignedTo,
      noGroup,
      noRegionGroup,
			noIndustryGroup,
      groupsMailings,
			groupsIndustry,
			groupsRegion,
    } = action.filterBy;

    const size_gt = get('gt', size);
    const size_lt = get('lt', size);
    
		try {
      let list = [];

      // Main fetch
      const mainFilter = () => {
        const filter = {};
        if(vip_type.length > 0) filter.vip_type = { match: vip_type };
        if (city) filter.city = { match: city };
        if (state.length) filter.state = { match: state.map(item => item.name).join() };
        if (zip_code) filter.zip_code = { eq: zip_code };
        if (createdAt_start && createdAt_end) filter.createdAt = { gte: createdAt_start, lte: createdAt_end };  
        if (search) {
          filter.name = { matchPhrasePrefix: search.toLowerCase() }
        }
        return filter;
      }
      // console.log('main filter', mainFilter())
      
      let total = null;
			let token = null;
			let result = [];
      if(!isObjectEmptyOrDefault(mainFilter())){
        while (true) {
          const data = await searchCompanysCustom({
            limit: 1000,
            limitContacts: 1000,
            filter: mainFilter(),
            sort: {field: 'name', direction: 'asc'},
            nextToken: token
          });
          // console.log(data)
          total = data.value.searchCompanys.total;
          token = data.value.searchCompanys.nextToken;
          result = result.concat(data.value.searchCompanys.items);
          if (result.length === total || token === null || total === null) break;
        }
        list = result; 

        if(assignedTo.length > 0){
					let assignedToList = [];
					assignedTo.map(employee => {
						const contacts = result.filter(item => item.assignedTo.id === employee.id);
						assignedToList.push(...contacts);
					});
					assignedToList = intersectionBy(assignedToList, 'id'); // get unique contacts
					list = intersectionBy(list, assignedToList, 'id'); // filter out main list
				} 

        if(unassigned){
          const companiesUnassigned = result.filter(item => item.assignedTo == null);
          list = intersectionBy(list, companiesUnassigned, 'id');
        }

        if (noGroup || noRegionGroup || noIndustryGroup){	
          if (noGroup){
            const companiesWithoutGroups = result.filter(item => item.groups.items.length === 0);
            list = intersectionBy(...companiesWithoutGroups, 'id');
          }
          if (noRegionGroup){
            const companiesWithoutRegionGroup = result.filter(item => {
              return item.groups.items.every(group => group.group.type !== 'region');
            });
            list = intersectionBy(...companiesWithoutRegionGroup, 'id');
          }
          if (noIndustryGroup){
            const companiesWithoutIndustryGroup = result.filter(item => {
              return item.groups.items.every(group => group.group.type !== 'industry');
            });
            list = intersectionBy(...companiesWithoutIndustryGroup, 'id');
          }
        }
        if (groupsMailings.length > 0){
          let mailingsList = [];
          groupsMailings.map(group => {
            const companies = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
            list = intersectionBy(list, companies, 'id');
            mailingsList.push(...companies);
          });
          mailingsList = intersectionBy(mailingsList, 'id'); // get unique companies
          list = intersectionBy(list, mailingsList, 'id'); // filter out main list
        }
        if (groupsRegion.length > 0){
          let regionList = [];
          groupsRegion.map(group => {
            const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
            regionList.push(...companies);
          });
          regionList = intersectionBy(regionList, 'id'); // get unique companies
          list = intersectionBy(list, regionList, 'id'); // filter out main list
        }
        if (groupsIndustry.length > 0){
          let industryList = [];
          groupsIndustry.map(group => {
            const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
            industryList.push(...companies);
          });
          industryList = intersectionBy(industryList, 'id'); // get unique companies
          list = intersectionBy(list, industryList, 'id'); // filter out main list
        }
        if(size_gt){
          const companiesSizeGt = result.filter(item => item.contacts.items.length >= size.value);
          list = intersectionBy(list, companiesSizeGt, 'id');
        }
        if(size_lt){
          const companiesSizeLt = result.filter(item => item.contacts.items.length <= size.value);
          list = intersectionBy(list, companiesSizeLt, 'id');
        }

      } else {
        if(assignedTo.length > 0){ // Filtering by assignedTo
          let result = []
          await Promise.all(assignedTo.map(async employee => {
            let token = null;
            while(true){
              const data = await getEmployeeWithCompanys({ 
                id: employee.id,
                limitCompanys: 2000,
                limitContacts: 100,
                nextTokenCompanys: token
              });
              // console.log(data)
              result = result.concat(data.value.getEmployee.companys.items);
              token = data.value.getEmployee.companys.nextToken;
              if(token === null) break;
            }
          }))
          if(list.length > 0) list = intersectionBy(list, result, 'id');
          else list = result;
  
          if (noGroup || noRegionGroup || noIndustryGroup){	
            if (noGroup){
              const companiesWithoutGroups = result.filter(item => item.groups.items.length === 0);
              list = intersectionBy(...companiesWithoutGroups, 'id');
            }
            if (noRegionGroup){
              const companiesWithoutRegionGroup = result.filter(item => {
                return item.groups.items.every(group => group.group.type !== 'region');
              });
              list = intersectionBy(...companiesWithoutRegionGroup, 'id');
            }
            if (noIndustryGroup){
              const companiesWithoutIndustryGroup = result.filter(item => {
                return item.groups.items.every(group => group.group.type !== 'industry');
              });
              list = intersectionBy(...companiesWithoutIndustryGroup, 'id');
            }
          }
          if (groupsMailings.length > 0){
            let mailingsList = [];
            groupsMailings.map(group => {
              const companies = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
              list = intersectionBy(list, companies, 'id');
              mailingsList.push(...companies);
            });
            mailingsList = intersectionBy(mailingsList, 'id'); // get unique companies
            list = intersectionBy(list, mailingsList, 'id'); // filter out main list
          }
          if (groupsRegion.length > 0){
            let regionList = [];
            groupsRegion.map(group => {
              const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
              regionList.push(...companies);
            });
            regionList = intersectionBy(regionList, 'id'); // get unique companies
            list = intersectionBy(list, regionList, 'id'); // filter out main list
          }
          if (groupsIndustry.length > 0){
            let industryList = [];
            groupsIndustry.map(group => {
              const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
              industryList.push(...companies);
            });
            industryList = intersectionBy(industryList, 'id'); // get unique companies
            list = intersectionBy(list, industryList, 'id'); // filter out main list
          }
          if(size_gt){
            const companiesSizeGt = result.filter(item => item.contacts.items.length >= size.value);
            list = intersectionBy(list, companiesSizeGt, 'id');
          }
          if(size_lt){
            const companiesSizeLt = result.filter(item => item.contacts.items.length <= size.value);
            list = intersectionBy(list, companiesSizeLt, 'id');
          }
  
        } else if (unassigned || noGroup || noRegionGroup || noIndustryGroup || groupsMailings.length > 0 || groupsRegion.length > 0 || groupsIndustry.length > 0 || size_gt || size_lt){ // Filtering by groups
          let token = null;
          let result = [];
          let i = 0;
          while(true){
            enqueueSnackbar(`Looking through companies ${i*1000+1}-${(i+1)*1000}`, { variant: 'default', autoHideDuration: 1000 });
            const data = await getCompanys({ 
              limitCompanys: 1000, 
              limitContacts: 100,
              nextToken: token
            });
            result = result.concat(data.value.listCompanys.items);
            token = data.value.listCompanys.nextToken;
            i = i+1;
            if(token === null) break;
          }
          if(list.length > 0) list = intersectionBy(list, result, 'id');
          else list = result;
  
          if(unassigned){
            const companiesUnassigned = result.filter(item => item.assignedTo == null);
            list = intersectionBy(list, companiesUnassigned, 'id');
          }
  
          if (noGroup || noRegionGroup || noIndustryGroup){	
            if (noGroup){
              const companiesWithoutGroups = result.filter(item => item.groups.items.length === 0);
              list = intersectionBy(...companiesWithoutGroups, 'id');
            }
            if (noRegionGroup){
              const companiesWithoutRegionGroup = result.filter(item => {
                return item.groups.items.every(group => group.group.type !== 'region');
              });
              list = intersectionBy(...companiesWithoutRegionGroup, 'id');
            }
            if (noIndustryGroup){
              const companiesWithoutIndustryGroup = result.filter(item => {
                return item.groups.items.every(group => group.group.type !== 'industry');
              });
              list = intersectionBy(...companiesWithoutIndustryGroup, 'id');
            }
          }
          if (groupsMailings.length > 0){
            let mailingsList = [];
            groupsMailings.map(group => {
              const companies = result.filter(item => item.groups.items.some(item => item.group.id === group.id));
              list = intersectionBy(list, companies, 'id');
              mailingsList.push(...companies);
            });
            mailingsList = intersectionBy(mailingsList, 'id'); // get unique companies
            list = intersectionBy(list, mailingsList, 'id'); // filter out main list
          }
          if (groupsRegion.length > 0){
            let regionList = [];
            groupsRegion.map(group => {
              const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
              regionList.push(...companies);
            });
            regionList = intersectionBy(regionList, 'id'); // get unique companies
            list = intersectionBy(list, regionList, 'id'); // filter out main list
          }
          if (groupsIndustry.length > 0){
            let industryList = [];
            groupsIndustry.map(group => {
              const companies = list.filter(item => item.groups.items.some(item => item.group.id === group.id));
              industryList.push(...companies);
            });
            industryList = intersectionBy(industryList, 'id'); // get unique companies
            list = intersectionBy(list, industryList, 'id'); // filter out main list
          }
          if(size_gt){
            const companiesSizeGt = result.filter(item => item.contacts.items.length >= size.value);
            list = intersectionBy(list, companiesSizeGt, 'id');
          }
          if(size_lt){
            const companiesSizeLt = result.filter(item => item.contacts.items.length <= size.value);
            list = intersectionBy(list, companiesSizeLt, 'id');
          }
        }
      }
			const direction = action.sortBy.direction.toLowerCase();

			return {
				result: orderBy(list, [action.sortBy.type], [direction]),
				total: list.length
			}
		} catch (e){
			console.log(e);
      enqueueSnackbar('Error fetching companies...', { variant: 'error', persist: true,
			errors: e.errors });
			return {
				result: [],
				total: 0
			}
		}
	}
};

export const useFetchTotalCompanys = (onDelete) => {
  const [total, setTotal] = useState(0);
  const { getCompanysTotal } = useActions(actions);
  const fetchTotal = async () => {
    try {
      let token = null;
      let result = 0;
      while(true){
        const data = await getCompanysTotal({ limit: 3000, nextToken: token });
        token = data.value.listCompanys.nextToken;
        result += data.value.listCompanys.items.length;
        // setTotal(total => total + data.value.listContacts.items.length);
        if(token === null) break;
      }
      setTotal(result);
    } catch(e) {
      console.log(e);
      setTotal(0);
    }
  };

  useEffect(() => { fetchTotal() }, [onDelete]);
  return [{ total }];
};

export const useCompany = (id, triggerFetch) => {
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getCompany, getGroupCompany } = useActions(actions);
  const [tasksTodo, setTasksTodo] = useState([]);
  const [tasksCompleted, setTasksCompleted] = useState([]);
  const fetchData = async () => {
    if(!id) return;
    setIsLoading(true);
    try {
      const _company = await getCompany({ id });
      const _groups = await Promise.all(_company.value.groups.items.map(async item => {
				const _group = await getGroupCompany({ id: item.id });
				return { ..._group.value.group, groupCompanyId: item.id, groupCompanyMailchimpStatus: item.mailchimp_status };
      }));
      setTasksTodo(orderBy(filter(_company.value.tasks.items, ['status', 'TODO']),['due_date'], ['asc'] ));
      setTasksCompleted(orderBy(filter(_company.value.tasks.items, ['status', 'COMPLETED']),['due_date'], ['asc'] ));
      setCompany({ ..._company.value, groups: _groups });
      // console.log('groups', _groups)
			// setCompany(prev => Object.assign(prev, { groups: _groups }));
    } catch(e) {
      console.log(e);
    }
    setIsLoading(false);
  }
  
  useEffect(() => { fetchData() }, [id, triggerFetch]);
  return [{ company, isLoading, tasksTodo, tasksCompleted }];
}

export const useCompanyActivity = (id, triggerFetch) => {
  const [activity, setActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getCompanyContactActivity } = useActions(actions);

  const fetchData = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const _activity = await getCompanyContactActivity({ id });
      const company = _activity.value.getCompany;
      // console.log('company', company)

      const groups = company.groups.items.map(groupCompany => groupCompany.group);
      // console.log('groups',groups)

			let getMailchimpActivity = await Promise.all(groups.map(async group => {
				if (group.mailchimp_list_id){
					try {
						// Possible activities: abuse, bounce, click, open, sent, unsub, or ecomm.
						const memberActivity = await useGetMemberActivity({
							list_id: group.mailchimp_list_id,
							mailchimp_id: company.mailchimp_id
						});
            // console.log('memberActivity',memberActivity);
            return memberActivity.data.activity;
					} catch(e) {
						console.log(e)
					}
				}
      }));
      // console.log('getMailchimpActivity',getMailchimpActivity)

      const mailchimpActivity = flow(
        list => compact(concat(...list)),
        list => filter(list, item => item.campaign_id),
        list => groupBy(list, item => item.campaign_id),
        list => Object.keys(list).map(campaign_id => orderBy(list[campaign_id], ['timestamp'], ['desc'])[0])
      )(getMailchimpActivity);
      // console.log('mailchimpActivity most recent activities',mailchimpActivity)

      // // Fetch campaign recipients
      // const campaignsSent = await Promise.all(
      //   compact(concat(...mailchimpActivity))
      //   .filter(item => item.action === 'sent')
      //   .map(async campaign => {
      //     const { campaign_id } = campaign;
      //     const { data } = await useGetCampaignRecipients({ campaign_id });
      //     const recipients = data.sent_to;
      //     return {
      //       ...campaign,
      //       recipients
      //     }
      //   })
      // );

      setActivity(sortActivity(company, mailchimpActivity));
        
    } catch(e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => { fetchData() }, [id, triggerFetch]);
  return [{ activity, isLoading }];
}

const sortActivity = ( company, mailchimpActivity ) => {
    // const { createdAt } = company;
    const contacts = get('contacts.items', company) || [];
    const createdAt = get('createdAt', company);
    const notes = get('notes.items', company);
    const messages = get('messages.items', company);
    const events = get('events.items', company);
    const tasksCompleted = filter(get('tasks.items', company) || [], ['status', 'COMPLETED']);

    let sortedActivity = [];

    if(createdAt) {
      sortedActivity.push({
        type: 'created',
        createdAt: createdAt
      })
    }

    if(contacts) {
      for(let contact of contacts) {
        const notes = get('notes.items', contact) || [];
        const contactForms = get('contactForms.items', contact) || [];
        const downloadForms = get('downloadForms.items', contact) || [];

        // Notes
        for(let note of notes){
          sortedActivity.push({
            type: 'note-contact',
            ...note,
            contactName: contact.full_name,
            contactId: contact.id
          });
        }

        // Contact Us Forms
        for(let contactForm of contactForms){
          sortedActivity.push({
            ...contactForm,
            contactName: contact.full_name,
            contactId: contact.id
          });
        }

        // Download Forms
        for(let downloadForm of downloadForms){
          sortedActivity.push({
            ...downloadForm,
            contactName: contact.full_name,
            contactId: contact.id
          })
        };

      }
    }

    if (mailchimpActivity){
      for(let activity of mailchimpActivity){
        const { campaign_id, timestamp, title, action } = activity;
        sortedActivity.push({
          type: 'mailchimp',
          campaign_id,
          createdAt: timestamp,
          title,
          action,
        })
      };
    }

    if (notes){
      for(let note of notes){
        sortedActivity.push({
          type: 'note-company',
          ...note
        })
      };
    }

    if (messages){
      for(let msg of messages){
        sortedActivity.push({
          type: 'message',
          ...msg
        })
      };
    }

    if(tasksCompleted){
      for(let task of tasksCompleted){
        sortedActivity.push({
          type: 'task',
          ...task,
          createdAt: task.updatedAt
        })
      }
    }

    if (events){
      for(let event of events){
        sortedActivity.push({
          type: 'event',
          ...event
        })
      }
    }

    return orderBy(sortedActivity,['createdAt'], ['desc', 'asc']);
}

export const usePipelinesByCompanyContacts = (id, triggerFetch) => {
  const [pipelines, setPipelines] = useState([]);
  const { getPipelinesByCompanyContacts } = useActions(actions);
  const fetchPipelines = async () => {
    try {
      let token = null;
      let result = [];
      let aggregatePipelines = []
      while(true){
        const data = await getPipelinesByCompanyContacts({ id, limit: 3000, nextToken: token });
        result = result.concat(data.value.getCompany.contacts.items);
        token = data.value.getCompany.nextToken;
        break;
      }
      aggregatePipelines = flatMap(result.map(item => uniqBy(aggregatePipelines.concat(item.pipeline.items), 'id')))
      const pipelines = aggregatePipelines.filter((item => item.archived === false))
      setPipelines(pipelines);
    } catch(e) {
      console.log(e);
    }
  };

  useEffect(() => { fetchPipelines() }, [id, triggerFetch]);
  return [{ pipelines }];
}

export const useUpdateCompany = () => {
  const { updateCompany, getCompany } = useActions(actions);
  const createGroupCompany = useCreateGroupCompany();
  const deleteGroupCompany = useDeleteGroupCompany();
  
  return async values => {
    values.groups = compact([
			get('mailingGroups', values), 
			get('regionGroups', values), 
			get('industryGroups', values)
		]);
    // console.log('values',values);
    const email = values.email ? values.email.toLowerCase() : values.email;
    const mailchimp_id = email ? crypto.createHash('md5').update(email).digest('hex') : null;

    // GET PREVIOUS COMPANY TO UPDATE MAILCHIMP MEMBER WITH PREVIOUS MAILCHIMP_ID
		const previousCompany = await getCompany({ id: values.id });
    // console.log(previousCompany)
    
    const updatedCompany = await updateCompany({ input: {
      id: values.id,
      name: values.name,
      email, 
      mailchimp_id,
      phone: values.phone,
      website: values.website,
      street_address: values.street_address,
      city: values.city,
      state: get('state.name', values) || null,
      zip_code: values.zip_code,
      // vip: values.vip,
      vip_type: get('vip_type.name', values) || null,
      sendNotification: values.sendNotification,
      country: values.country,
      companyAssignedToId: get('assignedTo.id', values),
      type: 'Company'
    }});
    // console.log('updatedCompany',updatedCompany)

    // UPDATE MAILCHIMP MEMBER (IF EMAIL CHANGES)
    const previousEmail = getOr('email', previousCompany.value, '').toLowerCase();
		if (previousEmail && previousEmail !== email) {
			values.groups && values.groups.map(async group => {
				if (group.mailchimp_list_id){
          const groupCompany = previousCompany.value.groups.items.find(item => item.group.id === group.id);
          // console.log('groupCompany', groupCompany)
          const mailchimp_status = get('mailchimp_status', groupCompany) || 'subscribed';
          // console.log('mailchimp_status',mailchimp_status)
					const updateMailchimpMember = await useUpdateMember({
            values: {
              email_address: email,
              status_if_new: mailchimp_status,
              status: mailchimp_status,
              merge_fields: {
                FNAME: values.name,
                LNAME: "",
                EMAIL: email,
              }
            },
            list_id: group.mailchimp_list_id,
						mailchimp_id: previousCompany.value.mailchimp_id,
					});
					console.log('updateMailchimpMember',updateMailchimpMember)
				}
			})
		}

    const { id: companyId } = updatedCompany.value;

    const initalGroupsCompanys = get('value.groups.items', updatedCompany)
			.map(item => ({ ...item, groupCompanyId: item.id }))
		;
    // console.log('initalGroupsCompanys', initalGroupsCompanys);

		// ADD AND/OR REMOVE GROUP CONTACTS
		const addGroupsCompanys = values.groups && values.groups
			.filter(group => !group.groupCompanyId)
			.map(async group => await createGroupCompany({
				groupCompanyCompanyId: companyId,
        groupCompanyGroupId: group.id,
        mailchimp_status: 'subscribed'
			}));
		// console.log('addGroupsCompanys', addGroupsCompanys)

		const removeGroupsCompanys = Promise.all(
			differenceBy(initalGroupsCompanys, values.groups, 'groupCompanyId')
			.map(groupCompany => deleteGroupCompany({ id: groupCompany.id }))
		);
		// console.log('removeGroupsCompanys', removeGroupsCompanys)

    return updatedCompany;
  }
};

export const useGetUnassignedCompanys = trigger => {
	const { getCompanysWithAssignedToJustIds } = useActions(actions);
	const [unassigned, setUnassigned] = useState([]);
	const fetchData = async () => {
		try {
      let token = null;
      let result = [];
      while(true){
				const data = await getCompanysWithAssignedToJustIds({ 
					limit: 3000, 
					nextToken: token 
				});
				result = result.concat(data.value.listCompanys.items);
				token = data.value.listCompanys.nextToken;
				if(token === null) break;
      }
			setUnassigned(result.filter(item => item.assignedTo == null));
		} catch(e) {
			console.log(e);
		}
  };
	useEffect(() => { fetchData() }, [trigger]);
	return [{ unassigned }];
};

export const useDeleteCompany = () => {
  const deleteGroupCompany = useDeleteGroupCompany();
	const { deleteCompany, createActivity } = useActions(actions);
	const { getCompanyWithGroups } = useActions(actions);

	return async ({ selected }) => {
    // console.log('SELECTED', selected)
    const companys = await Promise.all(selected.map(async companyId => {
			const data = await getCompanyWithGroups({ id: companyId });
			return data.value.getCompany;
		}));
		console.log('companies with group companies', companys)

		// Delete GroupCompanys
		const deleteGroupCompanys = await Promise.all(companys.map(async company => {
			return await flow(
				map(({ id }) => deleteGroupCompany({ id })),
				promises => Promise.all(promises),
			)(company.groups.items);
		}));
		// console.log('deleteGroupCompanys', deleteGroupCompanys)
		
		// Delete Companys
		const deleteCompanys = flow(
      map(company => {
        deleteCompany({ input: { id: company.id }});
        logDeleteCompany({ 
          companyId: company.id,
          companyName: company.name
        });
      }),
			promises => Promise.all(promises),
    );
    
    const logDeleteCompany = async({companyId, companyName}) => {
      const user = await Auth.currentAuthenticatedUser();
      await createActivity({
        input: {
          activityId: companyId,
          type: 'ACTIVITY',
          creatorId: user.username,
          activityCreatorId: user.username,
          action: "DELETED",
          activityType: "COMPANY",
          payload: {
            name: companyName
          }
        }
      });
    }

    return await deleteCompanys(companys);
  }
};

export const useUpdateMailchimpStatus = () => {
  const { updateGroupCompany } = useActions(actions);

  return async values => {
    const { company, newStatus, group } = values;
    try {
      const updatedMember = await useUpdateMember({
        values: {
          email_address: company.email,
          status_if_new: newStatus,
          status: newStatus,
        },
        list_id: group.mailchimp_list_id,
        mailchimp_id: company.mailchimp_id,
      });
      // console.log('updatedMember',updatedMember)
      if (updatedMember.success) {
        const updatedGroupCompany = await updateGroupCompany({
          input: {
            id: group.groupCompanyId,
            mailchimp_status: newStatus
          }
        });
        // console.log('updatedGroupCompany',updatedGroupCompany)
      }
      return updatedMember;
    } catch(e){
      console.log(e);
      return(e);
    }
  }
};

export const useUpdateMailchimpTagStatus = () => {
	const { updateGroupCompany } = useActions(actions);
  
	return async values => {
	  const { company, group, tag, status } = values;
	  try {
		  const updatedMemberTag = await useUpdateMemberTag({
			  list_id: group.mailchimp_newsletter_id,
			  mailchimp_id: company.mailchimp_id,
			  tag: tag,
			  status: status
		  })
		return updatedMemberTag;
	  } catch(e){
		console.log(e);
		return(e);
	  }
	}
  };

export const useAssignCompany = () => {
	const { updateCompany } = useActions(actions);

	return async values => {
		// console.log(values)
		const updatedCompanys = await Promise.all(values.selected.map(companyId => {
			return updateCompany({ input: {
				id: companyId,
				companyAssignedToId: get('id', values.assignedTo),
			}});
		}));
		// console.log('UPDATED COMPANYS', updatedCompanys)
		return updatedCompanys; 
	}
};

// export const useCompanysByNameAndEmail = () => {
//   const [companys, setCompanys] = useState([]);
//   const [isLoading, setIsLoading] = useState(null);
//   const { getCompanysByNameAndEmail } = useActions(actions);
//   const [token, setToken] = useState(null);

// 	const fetchCompanys = async () => {
//     try {
//       setIsLoading(true)
//       const data = await getCompanysByNameAndEmail({ 
//         sortDirection: 'ASC',
//         type: 'Company',
//         limit: 3000, 
//         nextToken: token
//       });
//       setCompanys(prev => prev.concat(data.value.listCompanysByName.items));
//       const nextToken = data.value.listCompanysByName.nextToken;
//       if (nextToken != null){
//         setToken(nextToken);
//       }
//       setIsLoading(false)
//     } catch(e) {
//       console.log(e);
//     }
// 	};
// 	useEffect(() => { fetchCompanys() }, [token]);
// 	return [{ companys, isLoading }];
// };

export const useMergeCompanys = () => {
	const { 
		updateCompany, 
    getCompany, 
    updateContact,
		updateNote, 
    updateMessageCompany,
		updateTask
	} = useActions(actions);
	const createGroupCompany = useCreateGroupCompany();
	const deleteGroupCompany = useDeleteGroupCompany();
	const deleteCompany = useDeleteCompany();

	/*
		STEPS
			- COMPANY TO UPDATE (COMPANY One, in the left)
				- Remove all groups
				- Update Company with new values
        - Add new groups
        - Update companyId of all Contacts from companyToDelete to companyToUpdate
				- Update companyId of all Notes from companyToDelete to companyToUpdate
				- Update companyId of all Messages from companyToDelete to companyToUpdate (if email companyToDelete is equal email new values)
				- Update companyId of all Tasks from companyToDelete to companyToUpdate
        
			- COMPANY TO DELETE (Company Two, in the right)
				- Delete company (useDeleteCompany)
	*/

	return async ({ companyToUpdate, companyToDelete, newGroups, newValues }) => {
		// console.log('companyToUpdate',companyToUpdate)
		// console.log('companyToDelete',companyToDelete)
		// console.log('newGroups',newGroups)
		// console.log('newValues',newValues)

		try {
			// REMOVE ALL GROUPS FROM COMPANY_TO_UPDATE
			const mailingsGroupCompanyId = get('mailings.groupCompanyId', companyToUpdate) || null;
			const regionGroupCompanyId = get('region.groupCompanyId', companyToUpdate) || null;
			const industryGroupCompanyId = get('industry.groupCompanyId', companyToUpdate) || null;

			const deletedGroups = await Promise.all([
				mailingsGroupCompanyId && await deleteGroupCompany({ id: mailingsGroupCompanyId }),
				regionGroupCompanyId && await deleteGroupCompany({ id: regionGroupCompanyId }),
				industryGroupCompanyId && await deleteGroupCompany({ id: industryGroupCompanyId }),
			]);
			console.log({deletedGroups})

			// UPDATE Company WITH NEW VALUES
			const updatedCompany = await updateCompany({ input: {
				id: companyToUpdate.id,
				email: newValues.email ? newValues.email.toLowerCase() : null,
        phone: newValues.phone,
        website: newValues.website,
				street_address: newValues.address.street_address,
				city: newValues.address.city,
				state: newValues.address.state,
				country: newValues.address.country,
				zip_code: newValues.address.zip_code,
				mailchimp_id: newValues.email ? crypto.createHash('md5').update(newValues.email.toLowerCase()).digest('hex') : null,
				companyAssignedToId: newValues.assignedTo ? newValues.assignedTo.id : null,
			}});
			// console.log('updatedCompany', updatedCompany)

			// ADD NEW GROUPS
			const mailingsGroupId = get('mailings.id', newValues) || get('mailings.id', newGroups) || null;
			const regionGroupId = get('region.id', newValues) || get('region.id', newGroups) || null;
			const industryGroupId = get('industry.id', newValues) || get('industry.id', newGroups) || null;
			// console.log('mailingsGroupId',mailingsGroupId)
			// console.log('regionGroupId',regionGroupId)
			// console.log('industryGroupId',industryGroupId)

      const createdGroups = await Promise.all([
				mailingsGroupId && await createGroupCompany({ 
					groupCompanyCompanyId: companyToUpdate.id,
					groupCompanyGroupId: mailingsGroupId,
					mailchimp_status: 'subscribed' 
				}),
				regionGroupId && await createGroupCompany({ 
					groupCompanyCompanyId: companyToUpdate.id,
					groupCompanyGroupId: regionGroupId,
					mailchimp_status: 'subscribed' 
				}),
				industryGroupId && await createGroupCompany({ 
					groupCompanyCompanyId: companyToUpdate.id,
					groupCompanyGroupId: industryGroupId,
					mailchimp_status: 'subscribed' 
				})
			]);
			console.log({createdGroups})

			const company = await getCompany({ id: companyToDelete.id }); // companyToDelete
      // console.log('company',company)
      
      // UPDATE ALL CONTACTS FROM COMPANY_TO_DELETE TO COMPANY_TO_UPDATE
			const contacts = company.value.contacts.items;
			const updatedContacts = await Promise.all(contacts.map(contact => updateContact({
				input: {
					id: contact.id,
					contactCompanyId: companyToUpdate.id
				}
			})));
			// console.log('updatedContacts',updatedContacts)

			// UPDATE ALL NOTES FROM COMPANY_TO_DELETE TO COMPANY_TO_UPDATE
			const notes = company.value.notes.items;
			const updatedNotes = await Promise.all(notes.map(note => updateNote({
				input: {
					id: note.id,
					noteCompanyId: companyToUpdate.id
				}
			})));
			// console.log('updatedNotes',updatedNotes)

      // UPDATE ALL MESSAGES FROM COMPANY_TO_DELETE TO COMPANY_TO_UPDATE
      const messages = company.value.messages.items;
      const updatedMessages = await Promise.all(messages.map(messageCompany => updateMessageCompany({
        input: {
          id: messageCompany.id,
          messageCompanyCompanyId: companyToUpdate.id,
        }
      })));
      // console.log('updatedMessages',updatedMessages)

      // UPDATE ALL TASKS FROM COMPANY_TO_DELETE TO COMPANY_TO_UPDATE
			const tasks = company.value.tasks.items;
			console.log(tasks)
			const updatedTasks = await Promise.all(tasks.map(task => updateTask({
				input: {
					id: task.id,
					taskCompanyId: companyToUpdate.id,
				}
			})));
			console.log('updatedTasks',updatedTasks)

			// DELETE COMPANY_TO_DELETE
			const deletedCompany = await deleteCompany({ selected: [ companyToDelete.id ]}); 
			// console.log('deletedCompany',deletedCompany)
		} catch(err){
			console.log(err);
		}
	}
};